import OilinessTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-oiliness/oiliness/OilinessTemplate';

import { AMOUNT } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinOiliness/Oiliness/OilinessTemplate',
  component: OilinessTemplate
};

const createStory = props => () => ({
  components: { OilinessTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><oiliness-template v-bind="$options.storyProps" /></div>'
});

export const Base = createStory({
  greasySkin: AMOUNT.NO
});

export const GreasySkin = createStory({
  hasGreasySkin: true,
  greasySkin: AMOUNT.CONSIDERABLY,
  openFacialPores: AMOUNT.MODERATELY,
  excessShine: 'no'
});
